
import './App.css';
import React from 'react';
import HomeRouting from './HomeRouting/HomeRouting';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import ScrollToTop from './helper/Scrolltotop';

function App() {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (

    <>
    <ScrollToTop/>
   
      <Header toggleDrawer={toggleDrawer} isOpen={isOpen}/>
      <HomeRouting toggleDrawer={toggleDrawer}/>
      <Footer/>
    </>
  );
}

export default App;

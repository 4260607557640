import React,{useEffect,useState} from 'react'
import axios from "axios"

const useBlogByTag = () => {
  const[id,setId]=useState("");
    const [BlogByTag,setBlogByTag]=useState([]);
    const[Loading,setLoading] = useState(false)
    
    console.log(BlogByTag);
    console.log("ID from use tage",id)
    useEffect(()=>{
      setLoading(true)
        
        axios({
          url: `${process.env.REACT_APP_API_URL}/blog/get-blog-by-tag/${id}`,
         
          method:"GET"
        }).then(
          (res) => {
          console.log(res.data.data.tags.blogtags.blog);
          let response = res.data.data.tags;
          setBlogByTag(response)
          setLoading(false)
          }

          ).catch( (err) => {
          console.log(err, "error message")
          setLoading(false)
          })
      
    
          
        },[id])
    
  return {
  
       BlogByTag,
       setBlogByTag,
       Loading,
       setId,
  
    
  }
}

export default useBlogByTag;
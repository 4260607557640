import React,{useEffect,useState} from 'react'
import axios from "axios"

const useFooter = () => {

const [setting,setSetting]=useState([]);
console.log(setting)
    useEffect(()=>{
        axios({
          url: `${process.env.REACT_APP_API_URL}/get-setting`,
         
          method:"GET"
        }).then(response=>setSetting(response.data.data.setting)).catch(error=>console.log(error.message))
       
     
      },[])
  return {
   setting,
   setSetting,
  
  }
}

export default useFooter
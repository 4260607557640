import React,{useEffect,useState} from 'react'
import axios from "axios"


const useLatestBlog = () => {
const [allLatestBlog,setAllLatestBlog]=useState([])
const[Loading,setLoading] = useState(false)
// console.log(allLatestBlog)
    useEffect(()=>{
      setLoading(true)
        axios({
          url: `${process.env.REACT_APP_API_URL}/latest/get`,
          method:"GET"
      //   }).then(response=>setAllLatestBlog(response.data.data.blogs), setLoading(false)).catch(error=>console.log(error.message),setLoading(false))
      // },[allLatestBlog])
    }).then(
      (res) => {
        console.log(res.data.data.blogs)
        let response = res.data.data.blogs;
        setAllLatestBlog(response)
        setLoading(false)
      }
    ).catch( (err) => {
    console.log(err, "error message")
    setLoading(false)
    })
  },[])
  return {
    allLatestBlog,
    setAllLatestBlog,
    Loading,
    setLoading,
  }
}

export default useLatestBlog
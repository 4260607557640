import React, { useEffect } from "react";
import "./Tag.css";
import usetag from "./usetag";
import useCategories from "../Categories/useCategories";
import { Link } from "react-router-dom";
const Tag = ({ id }) => {
  const { BlogByTag, setBlogByTag, setId, Loading } = usetag();
  const { BlogWithCategory, setBlogWithCategory } = useCategories();
  console.log(BlogByTag);
  useEffect(() => {
    setId(id);
  }, [id]);
  return (
    <>
      <div className="container-fluid tag-main ">
        <h3>{BlogByTag.tag}</h3>

        <div className="line"></div>

        {Loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row mt-5">
            {BlogByTag.blogtags?.map((item) => (
              <div className="col-md-3">
                <Link to={`/Detailspage/${item.blog?.blog_slug}`}>
                  <div class="item">
                    <div className="one">
                      <div className="two">
                        <div className="card-overlay">
                          <img
                            className="img-fluid"
                            src={item.blog?.image}
                            alt={item.blog?.image_alternative}
                          />
                        </div>
                      </div>
                      <div className="four">
                        <button className="btn card tag mb-3">
                          {item.blog.blog_category?.category_name}
                        </button>
                        <h3 className="text-light">{item.blog?.blog_title}</h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Tag;

import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
// import component 👇
import Drawer from "react-modern-drawer";
import Image2 from "../../assets/images/alexander-solodukhin-4394-unsplash-e1542192532699-50x50.jpg";
//import styles 👇
import useHeader from "./useHeader";
import "react-modern-drawer/dist/index.css";
import parse from "html-react-parser";
import useFooter from "../Footer/useFooter";
const Header = ({isOpen, toggleDrawer}) => {
  const { allBlogCategory, setAllBlogcategory } = useHeader();
  const { About, setAbout } = useHeader();
  const { setting, setSetting } = useFooter();
  // console.log(allBlogCategory);import parse from "html-react-parser"

 
  return (
    <>
      <div className="container">
        <Drawer
          open={isOpen}
          size="400px"
          onClose={toggleDrawer}
          direction="right"
          className="bla"
          
        >
          <div className="raju-sidebar">
            <div className="row menu mt-4">
              <div className="col-lg-6 col-6 blog-head">
                <h3>RajuBlog</h3>
              </div>

              <div className="col-lg-6 col-6 icon-menu">
                <div onClick={toggleDrawer} className="text-right">
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            </div>
            <div className="row">
              {About.map((item) => (
                <div className="col">
                  <div className="card drawer mt-4">
                    <div className="card-body">
                      <div className="round-img">
                        <img
                          src={item.image}
                          // alt={item.image_alternative}
                          className="rounded-circle"
                        />
                      </div>
                      <h6 className="mt-3 law">{item.title}</h6>
                      <p className="mt-3 new">
                        {/* Originally from New York City. Sports and music
                      enthusiast. Evil pop culture evangelist and music geek.
                      Sometimes a troublemaker. */}
                        {parse(item.description)}
                      </p>
                    </div>
                  </div>
                  <div className="card-social drawer mt-4 ">
                    <div className="card1-body">
                      {/* <h3>{title?title:""}</h3>
          <p className="mt-4 team">{description?description:""}</p> */}
                      <h3>Lets get social</h3>
                      <p>
                        We are a team of dedicated professionals delivering high
                        quality WordPress themes and plugins.
                      </p>

                      <div className="row ">
                        <div className="col-lg-3 col-md-3 col-3">
                          <div className="circle">
                            <p className="icon-item">
                              <a href={setting.facebook_link}>
                                <i class="fa-brands fa-facebook-f"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 circle">
                          <div className="circle">
                            <p className="icon-item1">
                              <a href={setting.twitter_link}>
                                <i class="fa-brands fa-twitter"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 circle">
                          <div className="circle">
                            <p className="icon-item2">
                              <a href={setting.linkedin_link}>
                              <i class="fa-brands fa-linkedin-in"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 ">
                          <div className="circle">
                            <p className="icon-item3">
                              <a href={setting.instagram_link}>
                                <i class="fa-brands fa-instagram"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Drawer>
      </div>
      <nav className="navbar navbar-expand-lg bg-light sticky ">
        <div className="container index">
          <div className="navbar-brand logo">
            <Link to="/">
              <h1 className="text-center main-head">RajuBlog</h1>
            </Link>
          </div>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
            <i class="fa-solid fa-bars"></i>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-auto">
              <li className="nav-item active ">
                <Link to="/">
                  <a className="nav-link" href="#">
                    HOME <span className="sr-only">(current)</span>
                  </a>{" "}
                </Link>{" "}
              </li>

              {allBlogCategory.map((item) => (
                <Link to={`/Categoriespage/${item.slug}`}>
                  <a className="nav-link">{item.category_name}</a>{" "}
                </Link>
              ))}

              <li className="nav-item">
                {" "}
                <a className="nav-link" href="#">
                  <div onClick={toggleDrawer}>
                    ABOUT <i class="fa-solid fa-bars"></i>
                  </div>
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

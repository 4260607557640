import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import useFooter from "./useFooter"; 
// import Data from "../Data/Data";
import ReadMoreAndLess from 'react-read-more-less';
import ReadMoreReadLess from "../ReadMore/Content";
import Content from "../ReadMore/Content";
const Footer = () => {
  const { setting, setSetting } = useFooter();
  // const [showMore, setShowMore] = useState(false);
  // const text  = Data;

  return (
    <>
      <footer class="footer  gray-bg ">
        <div class="container">
          <div class="row ">
            <div class="col-lg-5 mr-auto col-sm-6">
              <div class="widget mb-5 mb-lg-0 temp1">
                <div class="logo mb-4">
                  <h3>Raju Blog</h3>
                </div>
               
                <p>
                <Content/>
                </p>
                
                  

               
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="widget mb-5 mb-lg-0 temp">
                <h4 class="text-capitalize mb-3">Our social media</h4>
                <div class="divider mb-4"></div>

                
                <ul class="list-inline footer-socials mt-4">
                  <li class="list-inline-item">
                    <a href={setting.facebook_link} target="_blank">
                      <i class="fa-brands fa-facebook-f fb"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href={setting.twitter_link} target="_blank">
                      <i class="fa-brands fa-twitter tw"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href={setting.linkedin_link} target="_blank">
                      <i class="fa-brands fa-linkedin-in link"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="widget widget-contact mb-5 mb-lg-0 temp">
                <h4 class="text-capitalize mb-3">Get in Touch</h4>
                <div class="divider mb-4"></div>

                <div class="footer-contact-block mb-4">
                  <a href="">
                    <li className="mt-4">{setting.address}</li>
                  </a>
                  <a href="">
                    <li>{setting.company_name}</li>
                  </a>

                  <a href="">
                    <li>{setting.email1}</li>
                  </a>
                  <a href="tel:{setting.contact1}">
                    <li>{setting.contact1}</li>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-btm py-2 mt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6">
                <div class="copyright mt-4">
                  &copy;Developed by{" "}
                  <span>
                    <a href="">
                     {setting.company_name}
                     </a></span> 
                 
                   
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

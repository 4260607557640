import React, { useEffect } from "react";
import "./Categories.css";
import useCategories from "./useCategories";
import { Link } from "react-router-dom";
const Categories = ({ params }) => {
  const { BlogWithCategory, setBlogWithCategory, setSlug, Loading } =
    useCategories();
  console.log(BlogWithCategory);

  useEffect(() => {
    setSlug(params.slug);
  }, [params]);
  return (
    <>
      <div className="container-fluid categories ">
        {Loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row">
            {BlogWithCategory.get_blog?.map((item, get) => (
              <div className="col-md-3">
                <Link to={`/Detailspage/${item.blog_slug}`}>
                  <div class="item">
                    <div className="one">
                      <div className="two">
                        <div className="card-overlay">
                          <img
                            className="img-fluid"
                            src={item.image}
                            alt={item.image_alternative}
                          />
                        </div>
                      </div>
                      <div className="four">
                        <button className="btn card-btn mb-3">
                          {BlogWithCategory?.category_name}
                        </button>
                        <h3 className="text-light">{item.blog_title}</h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Categories;

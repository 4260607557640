import axios from 'axios';
import React,{useState} from 'react'

const useEmail = () => {
    const [email, setEmail] =useState ("");
    const [Loading, setLoading] = useState(false)

    const [response, setResponse] = useState({
        success: null,
        error: null,

    })
    console.log(email)
    const postEmail = async (e) => {
        e.preventDefault();
        if (email === ''){
        setResponse({
            success: null,
            error: "Empty field detected",
        })}
        else{
            setLoading(true)
            await axios({
                url: `${process.env.REACT_APP_API_URL}/enquiry/store`,
                method:"POST",
                data:{
                    email:email,
                }
             }).then (res=>{
                if (res.data.status===true){
                    setResponse({
                        success:"successfully sent",
                        error: null,
                    })
                    setEmail("")
                }
                else{
                    setResponse({
                        success: null,
                        error: res.data.message,
                    })
                }
            }).catch(error=>{
                setResponse({
                    success: null,
                    error: "server error",
                })
               
            })
            setLoading(false)
        }
        setTimeout(() =>{
            setResponse({
                success: null,
                error: null,
            })

        },5000)
    }
    return{
        email,
        setEmail,
        response,
        Loading1:Loading,
        setLoading,
        setResponse,
      postEmail
    }
}

export default useEmail
import React,{useEffect,useState} from 'react'
import axios from "axios"

const useFeatureBlogs = () => {
    const [allFeatureBlogs,setAllFeatureBlogs]=useState([]);
    const[Loading,setLoading] = useState(false)
  
    useEffect(()=>{
      setLoading(true)
        axios({
          url: `${process.env.REACT_APP_API_URL}/feature/get`,
         
          method:"GET"
        }).then(
          (res) => {
            console.log(res.data.data.blogs)
            let response = res.data.data.blogs;
            setAllFeatureBlogs(response)
            setLoading(false)
          }
        ).catch( (err) => {
        console.log(err, "error message")
        setLoading(false)
        })
      },[])
     
    
  return {
    
       allFeatureBlogs,
       setAllFeatureBlogs,
       Loading,
       setLoading,
    
}
}
export default useFeatureBlogs;
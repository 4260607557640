import React from "react";
import { useParams } from "react-router-dom";
import Categories from "../components/Categories/Categories";
import { Helmet } from "react-helmet";
const Categoriespage = () => {
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>Raju Blog</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <Categories params={params} />
    </>
  );
};
export default Categoriespage;

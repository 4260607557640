import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Tag from "../components/Tag/Tag";
const Tagpage = () => {
    const params = useParams();
    console.log("Params from tag page",params.id)
  return (
    <>
      <Helmet>
        <title>Raju Blog</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <Tag id={params.id}/>
    </>
  );
};

export default Tagpage;

import React, { useEffect, useState } from "react";
import "./AllDetails.css";

import useAllDetails from "./useAllDetails";
import { Link } from "react-router-dom";
import { RWebShare } from "react-web-share";
import parse from "html-react-parser";
import useFooter from "../Footer/useFooter";
import useCategories from "../Categories/useCategories";
import { ShareButton } from "react-custom-share";
function AllDetails({ slug }) {
  const shareButtonProps = {
    url: "www.facebook.com",
    network: "Facebook",
    text: "Give it a try - react-custom-share component",
    longtext:
      "Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch.",
  };
  const shareButton1Props = {
    url: "www.twitter.com",
    network: "Twitter",
    text: "Give it a try - react-custom-share component",
    longtext:
      "Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch.",
  };
  const shareButton2Props = {
    url: "www.linkedin.com",
    network: "Linkedin",
    text: "Give it a try - react-custom-share component",
    longtext:
      "Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch.",
  };
  
   const shareButton3Props = {
    url: "www.email.com",
    network: "Email",
    text: "Give it a try - react-custom-share component",
    longtext:
      "Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch.",
  };
  const {
    allBlogs,
    setAllBlogs,
    setSlug,
    allBlogCategory,
    setAllBlogcategory,
    SimilarBlog,
  } = useAllDetails();
  const { setting, setSetting } = useFooter();

  const { BlogWithCategory, setBlogWithCategory } = useCategories();
  // const { ReadingBlog, setReadingBlog } = useAllDetails();

  console.log("aghjkjahgbj", allBlogs.blog_tags);
  useEffect(() => {
    setSlug(slug);
  }, [slug]);
  // useEffect(() => {
  //   setId(id);
  // }, [id]);

  console.log(allBlogs, "kashdvcbaslidgvsbdjc sdcv");

  return (
    <>
      <div className="First-main ">
        <button className=" btn btn-main">
          {allBlogs ? allBlogs?.blog_category?.category_name : null}
        </button>
      </div>

      <div className="creativity text-center mt-4">
        {allBlogs ? allBlogs.blog_title : null}
      </div>
      <div className="container mt-5">
        <div className="img-item">
          <img src={allBlogs ? allBlogs.image : null} alt="image" />
        </div>

        <div className="row mt-4">
          <div className="col-lg  proactively">
            <p>
              {allBlogs.blog_description
                ? parse(allBlogs.blog_description.substring(0,2000))
                : null}
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
       
      </div>

      <div className="container  mt-4 ">
        <div className="row ">
          <div className="col-lg-6 similar">
            <div className="linee mt-4"></div>
            <h5 className="mt-4">Share</h5>

            <div className="row mt-4">
              <div className="col-lg-3 col-md-3 col-3 share ">

                <ShareButton {...shareButtonProps}>
                  <button className="face">
                    {" "}
                    <i class="fa-brands fa-facebook-f"></i>
                  </button>
                </ShareButton>
              </div>
              <div className="col-lg-3 col-md-3 col-3">
                <ShareButton {...shareButton1Props}>
                  <button className="twitter">
                    {" "}
                    <i class="fa-brands fa-twitter"></i>
                  </button>
                </ShareButton>
              </div>
              <div className="col-lg-3 col-md-3 col-3">
                <ShareButton {...shareButton2Props}>
                  <button className="linkedin">
                    {" "}
                    <i class="fa-brands fa-linkedin-in"></i>
                  </button>
                </ShareButton>
              </div>
              <br></br>
              <div className="col-lg-3 col-md-3 col-3">
                <ShareButton {...shareButton3Props}>
                  <button className="email">
                    {" "}
                    <i class="fa-solid fa-envelope"></i>
                  </button>
                </ShareButton>
              </div>
            </div>
            <div className=" text-left similar mt-5">
          <h5>Similar tags</h5>

          <div className="row famous mt-4">
            
            {allBlogs.blog_tags?.map((item) => (
              <Link to={`/Tagpage/${item.tag?.id}`}>
              <p>{item?.tag?.tag}</p>
              </Link>
            ))}
          
          </div>
        </div>
          </div>
          <div className="col-lg-6">
            <div className="card-social mt-4 ">
              <div className="card1-body">
                <h3>Lets get social</h3>
                <p>
                  We are a team of dedicated professionals delivering high
                  quality WordPress themes and plugins.
                </p>

                <div className="row ">
                  {/* <div className="col-offset-3"></div> */}
                  <div className="col-lg-2 col-md-2 col-2">
                    <div className="circle">
                      <p className="icon-item">
                        <a href={setting.facebook_link}>
                          <i class="fa-brands fa-facebook-f"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-2 circle">
                    <div className="circle">
                      <p className="icon-item1">
                        <a href={setting.twitter_link}>
                          <i class="fa-brands fa-twitter"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-2 circle">
                    <div className="circle">
                      <p className="icon-item2">
                        <a href={setting.linkeidin_link}>
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-2 ">
                    <div className="circle">
                      <p className="icon-item3">
                        <a href={setting.instagram_link}>
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-offset-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reading mt-5 ">
        <h2>Further reading</h2>
        <div className="underline"></div>
        <div className="container">
          <div className="row">
            {SimilarBlog.map((item) => (
              <div className="col-lg-4 col-md-4 col-sm-4 page">
                <div className="image1">
                  <img src={item.image} />
                </div>
                <br />
                <button className=" btn btn-art ">
                  {item.blog_category?.category_name}
                </button>
                <Link to={`/Detailspage/${item.blog_slug}`}>
                  <p className="top mb-4">{item.blog_title}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default AllDetails;

import React from 'react'
import LatestArticle from '../components/Home-details/LatestArticle/LatestArticle'
import UnleashFirst from '../components/Home-details/UnleashFirst/UnleashFirst'
import FeaturedArticle from '../components/Home-details/FeaturedArticle/FeaturedArticle'
import { Helmet } from "react-helmet";
const Allpages = ({toggleDrawer}) => {
  return (
    <div>
       <Helmet>
      <title>Raju Blog</title>
      <meta name="description" content="Web site created using create-react-app" />
  </Helmet>
        <UnleashFirst toggleDrawer={toggleDrawer}/>
        <LatestArticle/>
        <FeaturedArticle/>
    </div>
  )
}

export default Allpages
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useAllDetails = () => {
  const [allBlogs, setAllBlogs] = useState([])
  const [allBlogCategory, setAllBlogCategory] = useState([]);
  const [SimilarBlog, setSimilarBlog] = useState([]);
  const [slug, setSlug] = useState("")

  useEffect(() => {
    if (slug) {
      axios({
        url: `${process.env.REACT_APP_API_URL}/blogCategory/get`,

        method: "GET"
      }).then(
        (res) => {

          let response = res.data.data.blogs;
          setAllBlogCategory(response)
        }
      ).catch((err) => {
        console.log(err, "error message")

      })
      axios({
        url: `${process.env.REACT_APP_API_URL}/blog/get-similar-blog/${slug}`,

        method: "GET"
      }).then(

        (res) => {

          let response = res.data.data.similarBlogs;
          setSimilarBlog(response)

        }

      ).catch((err) => {
        console.log(err, "error message")

      })
      axios({
        url: `${process.env.REACT_APP_API_URL}/blog/single/${slug}`,
        method: "GET"
      }).then(response => setAllBlogs(response.data.data.blog))
      
        .catch(error => console.log(error.message))
    }



  }, [slug])


  return {

    allBlogs,
    setAllBlogs,
    setSlug,
    allBlogCategory,
    setAllBlogCategory,
    SimilarBlog,
    setSimilarBlog


  }
}

export default useAllDetails;
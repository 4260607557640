import React,{useEffect,useState} from 'react'
import axios from "axios"

const useBlogWithCategory = () => {
  const[slug,setSlug]=useState("")
    const [BlogWithCategory,setBlogWithCategory]=useState([]);
    const[Loading,setLoading] = useState(false)
    
    console.log(BlogWithCategory)
    console.log(slug)

    useEffect(()=>{
      setLoading(true)
        axios({
          url: `${process.env.REACT_APP_API_URL}/blog/get-by-category/${slug}`,
         
          method:"GET"
        }).then(
          (res) => {
          console.log(res.data.data.category.get_blog)
          let response = res.data.data.category;
          setBlogWithCategory(response)
          setLoading(false)
          }

          ).catch( (err) => {
          console.log(err, "error message")
          setLoading(false)
          })
        },[slug])
    
  return {
  
       BlogWithCategory,
       setBlogWithCategory,
       setSlug,
       Loading,
       setLoading
    
  }
}

export default useBlogWithCategory;
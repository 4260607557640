import React from "react";
import AllDetails from "../components/AllDetails/AllDetails";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const Detailspage = () => {
const params = useParams();
  return (
    <>
      <Helmet>
        <title>Raju Blog</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <AllDetails slug={params.slug} />
    </>
  );
};

export default Detailspage;

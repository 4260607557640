import React from 'react'
import Allpages from '../Pages/Allpages'
import Detailspage from '../Pages/Detailspage'
import Categoriespage from '../Pages/Categoriespage'
import Tagpage from '../Pages/Tagpage'
import {Routes, Route} from 'react-router-dom'

function HomeRouting({toggleDrawer}) {
  return (
   <>
     <Routes>
     <Route exact path='/' element={< Allpages toggleDrawer={toggleDrawer}/>}></Route>
     <Route exact path='/Detailspage/:slug' element={< Detailspage />}></Route>
     <Route exact path='/Categoriespage/:slug' element={< Categoriespage />}></Route>
     <Route exact path='/Tagpage/:id' element={< Tagpage />}></Route>
     </Routes>
    
   </>
  )
}
export default HomeRouting

import React,{useEffect,useState} from 'react'
import axios from "axios"

const useHeader = () => {
const [allBlogCategory,setAllBlogCategory]=useState([])
const [About,setAbout]=useState([])

    useEffect(()=>{
        axios({
          url: `${process.env.REACT_APP_API_URL}/blogCategory/get`,
         
          method:"GET"
        }).then(response=>setAllBlogCategory(response.data.data.blogs)).catch(error=>console.log(error.message))
        axios({
          url: `${process.env.REACT_APP_API_URL}/about/get`,
         
          method:"GET"
        }).then(response=>setAbout(response.data.data.abouts)).catch(error=>console.log(error.message))
     
      },[])
  return {
    allBlogCategory,
    setAllBlogCategory,
    About,
    setAbout,
  
  }
}

export default useHeader
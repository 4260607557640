// import React, { useState } from 'react'
// import './ReadMoreReadLess.css'
// const ReadMoreReadLess = ({ limit, children }) => {
//     const text = children;
//     const [isReadMoreShown, setReadMoreShown] = useState(false);
//     const toggleBtn = () => {
//         setReadMoreShown(prevState => !prevState)
//     }
//   return (
//     <>
//     <div className='read-more-read-less'>
//         {isReadMoreShown ? text: text.toString().substr(0, limit)}
//        <button className='btn btn-read' onClick={toggleBtn}>{isReadMoreShown ? 'Read Less' :   '...Read More'}</button>
//     </div>
//     </>
//   )
// }

// export default ReadMoreReadLess

import React, { useState } from "react";
  
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...learn more" : " show less"}
      </span>
    </p>
  );
};
  
const Content = () => {
  return (
    <div className="container">
      <p>
        <ReadMore>
        Tempora dolorem voluptatum nam vero assumenda voluptate,
        facilis ad eos obcaecati tenetur veritatis eveniet distinctio
        possimus. Te Tempora dolorem voluptatum nam vero assumenda voluptate,
        facilis ad eos obcaecati tenetur veritatis eveniet distinctio
        possimus. 
        </ReadMore>
      </p>
    </div>
  );
};
  
export default Content;
import React, { useState } from "react";
import "./FeaturedArticle.css";
import useFeatureArticle from "./useFeatureArticle";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import useEmail from "./useEmail";

const FeaturedArticle = () => {
  const { allFeatureBlogs, Loading } = useFeatureArticle();
  const { email, setEmail, postEmail, response,Loading1 } = useEmail();

  return (
    <>
      <div className="article">
        <div className="container">
          <h2 className="text-center featured-article">Featured Article</h2>

          <div className="underline"></div>

          {Loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            allFeatureBlogs.map((item) => (
              <div>
                <div className="image-main mt-5 ">
                  <img
                    src={item.image}
                    alt={item.image_alternative}
                    className="img-item "
                  />
                </div>
                <div className="mt-5">
                  <div className="motivation-section">
                    <button className="btn subscribe-btn">
                      {item.category_name}
                    </button>
                    <h2 className="motivation-section-head">
                      {item.blog_title}
                    </h2>

                    <p>{parse(item.blog_description.substring(0, 300))}</p>
                  </div>
                </div>
              </div>
            ))
          )}

          <div className="card-news mt-5">
            <div className="card-body">
              {response.success ? (
                <div className="alert alert-primary" role="alert">
                  Thank you for subscribing us!
                </div>
              ) : null}
              {response.error ? (
                <div className="alert alert-danger" role="alert">
                  {response.error}
                </div>
              ) : null}
              <h3 className="mt-4 letter-main">Newsletter</h3>
              <p className="mt-4 ">
                Make sure to subscribe to our newsletter and be the first to
                know the news.
              </p>
              <form>
                <input
                  type="text"
                  className="form-control mt-4"
                  placeholder="Your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </form>
              {Loading1 ? (
                <div className="mt-4">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
              
                <button
                  className=" btn subscribe-btn mt-4"
                  type="submit"
                  onClick={postEmail}

                >
                    
                  Subscribe
                  
                </button>
               
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedArticle;

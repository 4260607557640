import React from 'react'
import { Link } from 'react-router-dom'
import image1 from '../../../assets/images/1.jpg'
import './UnleashFirst.css'

const UnleashFirst = ({toggleDrawer}) => {
  return (
   <>
   <div className="container first-banner ">
     <div className="row ">
       <div className="col-lg-6  col-12 col-md-6">
         <div>
           <img src={image1}  alt="" className='first-image img-fluid' />
         </div>
       </div>
       <div className="col-lg-6 col-12 col-md-6 pt-5  ">
         <div className='first-ban-intro'>
          <h2 id='main-main'>Unleash your <br />
          creativity</h2>
          <p>Johannes is a theme carefully designed and developed to get the most out of the new WordPress editor. Unleash the power of blocks - an awesome new way of creating rich content in WordPress!</p>
        
          
            <div>
            <button className='btn first-btn' onClick={toggleDrawer}>Find out more</button>
            </div>
           
        
         </div>
       </div>
     </div>
   </div>
   </>
  )
}

export default UnleashFirst

import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./LatestArticle.css";

import { Link } from "react-router-dom";
import useLatest from "./useLatest";
const LatestArticle = () => {
  const { allLatestBlog, setAllLatestBlog, Loading } = useLatest();

  return (
    <>
      <div className="container ">
        <div>
          <h2 className="text-center latest-art">Latest Article</h2>
        </div>
        <div className="underline"></div>
        
        
          {Loading ? (
            
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
         
          ) : (
            <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          nav={true}
          dots={false}
          responsive={{
            0: {
              items: 1,
            },
            750: {
              items: 2,
            },
            1000: {
              items: 3,
            },
          }}
        >
            {allLatestBlog.map((item) => (
              <div class="item">
                <Link to={`/Detailspage/${item.blog_slug}`}>
                  <div className="one">
                 
                    <div className="two">
                    <div className="card-overlay">
                      <img
                        className="img-fluid"
                        src={item.image}
                        alt={item.image_alternative}
                      />
                    </div>
                    </div>
                    <div className="four">
                      <button className="btn card-btn mb-3">{item.category_name}</button>
                      <h3 className="text-light">{item.blog_title}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
         
        </OwlCarousel>
         )}
        
      </div>
    </>
  );
};

export default LatestArticle;
